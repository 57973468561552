import React, { useState } from 'react';
import styles from './Wrapper.module.css';

const Wrapper = () => {
  let amountInput = 0;
  let desiredStrengthInput = 0;
  let shotStrengthInput = 0;

  const [resultMessage, setResultMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    captureInputs();

    try {
      if (amountInput === "" || desiredStrengthInput === "" || shotStrengthInput === "") {
        setResultMessage("ERROR: Please enter values before calculating");
        throw new Error("Not all fields have values");
      }

      if (
        typeof (amountInput) !== "number" ||
        typeof (desiredStrengthInput) !== "number" ||
        typeof (shotStrengthInput) !== "number"
      ) {
        setResultMessage("ERROR: Please enter a numebr");
        throw new Error("Input not a number");
      }

      const calculation = ((amountInput * desiredStrengthInput) / shotStrengthInput);
      setResultMessage(`You're going to need ${calculation}ml of nicotine shots for your ${amountInput}ml of juice!`);
    } catch (e) {
      console.error(e.message);
    }
  }

  const captureInputs = () => {
    amountInput = Number(document.getElementById('liquidAmount').value);
    desiredStrengthInput = Number(document.getElementById('desiredStrength').value);
    shotStrengthInput = Number(document.getElementById('nicShotStrength').value);
  }

  const resetFields = () => {
    document.getElementById('liquidAmount').value = "";
    document.getElementById('desiredStrength').value = "";
    document.getElementById('nicShotStrength').value = "";
    setResultMessage("");
  }

  return (
    <div className={styles.wrapper}>
      <h1 id={styles.title}>Nicotine Shot Calculator</h1>
      <div id={styles.contentWrapper}>
        <div>
          <p>Easily work out the amount of nicotine shots to add to your 0mg e-liquid to get to your desired strength!</p>
        </div>
        <div>
          <label htmlFor="liquidAmount">Amount of 0mg Liquid</label>
          <div>
            <input id="liquidAmount" type="text" />
            <span className={styles.measurement}>ml</span>
          </div>
          <br />
          <label htmlFor="desiredStrength">Desired Strength</label>
          <div>
            <input id="desiredStrength" type="text" />
            <span className={styles.measurement}>mg</span>
          </div>
          <br />
          <label htmlFor="nicShotStrength">Nic Shot Strength</label>
          <div>
            <input id="nicShotStrength" type="text" />
            <span className={styles.measurement}>mg</span>
          </div>
          <br />
          <button id={styles.button} onClick={handleSubmit}>Calculate</button>
          <button id={styles.button} onClick={resetFields}>Clear</button>
        </div>
      </div>
      <div id={styles.results}>
        <h1>{resultMessage}</h1>
      </div>
    </div>
  );
}

export default Wrapper;
