import React, { useEffect } from 'react';
import Wrapper from './components/Wrapper/Wrapper.js';
import './App.css';

const footerMessage = "</> with <3 in React";
const copyright = `Copyright ${new Date().getFullYear()} All Rights Reserved`;

function App() {
  useEffect(() => {
    document.title = "Nic Shot Calculator";
  }, [])

  return (
    <div className="App">
      <Wrapper />
      <footer>
        <h5>Lost?  <a target="_blank" rel="noreferrer" href="https://www.brdlyt.dev">Click Here!</a></h5>
        <p>{footerMessage}</p>
        <p>{copyright}</p>
      </footer>
    </div>
  );
}

export default App;
